<template>
  <div v-if="property">
    <div v-if="!locked">
      <CRow align-horizontal="between" align-vertical="center" class="mb-2">
        <CCol sm="auto" class="d-flex align-items-center">
          <h3>
            {{ report.title }} for {{ property.property.name }}
          </h3>
        </CCol>
        <CCol sm="auto" class="d-flex align-items-center">
          <div class="pr-2 daterange-select">
            Showing reports for
          </div>
          <RDateRangePicker
            v-model="dateRange"
            :max-date="new Date()"
            :min-date="minimumStartDate"
          />
        </CCol>
      </CRow>
      <CRow align-horizontal="between" align-vertical="center" class="pb-2">
        <CCol sm="auto" class="d-flex">
          <portal-target name="report-subheader" />
        </CCol>
        <CCol sm="auto" class="d-flex align-items-center controls">
          <CButton shape="pill" @click="handleRefresh">
            <CIcon name="md-refresh" />
            Refresh
          </CButton>
          <CButton class="mr-2 ml-2" shape="pill" @click="handleExport">
            <CIcon name="exporting" />
            Export
          </CButton>
        </CCol>
      </CRow>
      <CRow class="mt-3">
        <CCol>
          <RSpinnerOverlay
            :loading="loading"
            :opacity="0.8"
            :z-index="1028"
            :is-full-page="true"
          >
            <router-view
              ref="report"
              v-bind="$props"
              :features="property.property.features"
              :date-range="tzDateRange"
              :loading="loading"
              @loading="loading = $event"
              @no-data="handleNoData"
            />
          </RSpinnerOverlay>
        </CCol>
      </CRow>
      <CRow class="mb-3">
        <CCol class="timezone">
          All data displayed in {{ tzShort }}
        </CCol>
      </CRow>

      <DataExportModal
        :show="showDataExportModal"
        :data="exportData"
        :file-name="exportFileName"
        @close="handleExportModalClose"
      />
      <NewFeatureModal
        :feature-name="report.feature"
        :show="showNewFeatureModal"
        @close="showNewFeatureModal = false"
      />
    </div>
    <div v-else>
      <UnlockFeature
        :feature-name="report.feature"
        :feature-link="report.link"
      />
    </div>
  </div>
</template>

<script>
import { compareAsc, differenceInDays, subMonths } from 'date-fns'
import Mixpanel from 'mixpanel-browser'

import { RDateRangePicker } from '@/components/dates'
import { DataExportModal } from '@/components/modals'
import { RSpinnerOverlay } from '@/components/spinners'
import constants from '@/utils/constants'
import { slugify } from '@/utils/strings'
import { NewFeatureModal, UnlockFeature } from '@/views/reporting/_components'

export default {
  name: 'BaseReport',
  components: {
    RSpinnerOverlay,
    RDateRangePicker,
    DataExportModal,
    NewFeatureModal,
    UnlockFeature
  },
  props: {
    company: {
      type: Object,
      required: true
    },
    property: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      dateRange: {
        start: subMonths(new Date(), 1),
        end: new Date()
      },
      loading: false,
      exportData: [],
      showDataExportModal: false,
      showNewFeatureModal: false
    }
  },
  computed: {
    tzDateRange () {
      return {
        ...this.dateRange,
        tz: Intl.DateTimeFormat().resolvedOptions().timeZone
      }
    },
    tzShort () {
      const locale = new Date().toLocaleString('en-us', { timeZoneName: 'short' }).split(' ')
      return locale[locale.length - 1]
    },
    /**
     * Returns the property's start date or trial start date, whichever comes first.
     * NOTE: when using new Date() with a date string as an argument, you have to specify
     * the time. Otherwise, you can get the wrong date because of your time zone.
     */
    minimumStartDate () {
      const startDate = this.property.property.start_date
      const trialStartDate = this.property.property.trial_start_date
      if (startDate && trialStartDate) {
        const start = new Date(startDate + 'T00:00')
        const trial = new Date(trialStartDate + 'T00:00')
        return compareAsc(start, trial) === 1 ? trial : start
      } else if (trialStartDate) {
        return new Date(trialStartDate + 'T00:00')
      } else if (startDate) {
        return new Date(startDate + 'T00:00')
      }
      return undefined
    },
    products () {
      return this.$store.state.rooof.products
    },
    locked () {
      switch (this.$route.name) {
        case 'EmailReport':
          return !this.property.property.features.emailHandling
        case 'PhoneReport':
          return !this.property.property.features.callTracking
        case 'WebsiteTrafficReport':
          return !this.property.property.features.redirectLinks
        default:
          return false
      }
    },
    report () {
      const report = constants.rooof.ReportTypes[this.$route.name]
      if (process.env.NODE_ENV === 'production' && !this.$store.getters['auth/isStaff']) {
        Mixpanel.track(report.title, {
          Company: this.company.human_name,
          Property: this.property.property.name
        })
      }
      return report
    },
    exportFileName () {
      const propertyName = slugify(this.property.property.name)
      return `${this.$route.name}-${propertyName}`
    }
  },
  watch: {
    // Sets the start of date range to the minimum start date if the minimum comes after the default start range.
    property () {
      if (this.minimumStartDate && compareAsc(this.minimumStartDate, this.dateRange.start) === 1) {
        this.dateRange.start = this.minimumStartDate
      }
    }
  },
  methods: {
    /**
     * onClick handler for export button.
     */
    async handleExport () {
      if (this.$refs['report'].exportData) {
        if (process.env.NODE_ENV === 'production' && !this.$store.getters['auth/isStaff']) {
          Mixpanel.track(`Export - ${this.report.title}`, {
            Company: this.company.human_name,
            Property: this.property.property.name
          })
        }
        this.exportData = await this.$refs['report'].exportData()
        this.showDataExportModal = true
      }
    },
    /**
     * onClose handler for data export modal.
     */
    handleExportModalClose () {
      this.exportData = []
      this.showDataExportModal = false
    },
    /**
     * onClick handler for refresh button.
     */
    handleRefresh () {
      this.$refs['report'].refresh && this.$refs['report'].refresh()
    },
    /**
     * No data handler.
     * Gets the newest subscription start date and
     * checks if it has been more than 30 days.
     * If so, do not display the new feature modal.
     */
    handleNoData () {
      const latestStartDate = this.getLatestSubscriptionDate()
      const subscriptionDuration = differenceInDays(new Date(), latestStartDate)

      if (!latestStartDate || subscriptionDuration <= 30) {
        this.showNewFeatureModal = true
      }
    },
    /**
     * Gets the most recent subscription date for the current feature selected.
     * @returns {Object} most recent start date (including trial).
     */
    getLatestSubscriptionDate () {
      let dates = []

      for (const productSubscription of this.property.property.product_subscriptions) {
        const product = this.products.find(product => product.name === productSubscription.product)

        if (product.features.includes(this.report.feature)) {
          dates.push(productSubscription.start_date)
          dates.push(productSubscription.trial_start_date)
        }
      }

      for (const featureSubscription of this.property.property.feature_subscriptions) {
        if (featureSubscription.feature === this.report.feature) {
          dates.push(featureSubscription.start_date)
          dates.push(featureSubscription.trial_start_date)
        }
      }

      dates = dates.filter(date => date)

      return dates.length ? new Date(this.getLatestDate(dates)) : null
    },
    /**
     * Returns the most recent date given a set of dates.
     * @param {String[]} dates - Array of dates.
     * @returns {String} most recent date.
     */
    getLatestDate (dates) {
      let latestDate = ''
      for (const date of dates) {
        if (date > latestDate) {
          latestDate = date
        }
      }
      return latestDate
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .input {
  color: $info;
}
.title {
  color: #76849b;
}
.daterange-select {
  color: $info;
  font-size: 1.1rem;
}
.controls button:hover {
  background-color: #e9ebf180;
  color: $gray-900;
}
.c-icon {
  color: $secondary-700;
  height: 1.15em;
  width: 1.15em;
  margin: 0;
}
.timezone {
  color: $gray-400;
  text-align: right;
}
</style>
